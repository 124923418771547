import React from 'react'
import { Route, Switch, useParams } from 'react-router-dom'
import { url2section } from 'ion-sections'
import loadable from '@loadable/component'
import NewspaperButton from '../../../assets/images/buttons/sundayindependent-button.png'
import { DefaultSectionPageSkeletonWrapper, ArticlePageSkeleton } from '../components/Skeletons'

const TitleSectionPage = loadable(() => import('../pages/TitleSectionPage'), {
  fallback: <DefaultSectionPageSkeletonWrapper />
})
const TagPage  = loadable(() => import('../pages/TagPage'))
const Article = loadable(() => import('../pages/Article'), {
  fallback: <ArticlePageSkeleton />
})

const RenderArticle = (props) => {
  const { contentKey } = useParams()
  return <Article {...props} contentKey={contentKey} />
}

const SundayIndependent = (props) => (
  <Switch>
    <Route path='/sundayindependent' exact render={() => (
      <TitleSectionPage {...props}
        section='sundayindependent'
        sectionLabel='Sunday Independent'
        relatedSections={[{ id: 'sundayindependent/news' }, { id: 'sundayindependent/lifestyle' }, { id: 'sundayindependent/analysis' }, { id: 'sundayindependent/international' }, { id: 'sundayindependent/dispatch' }]}
        title='Sunday Independent'
        description='-'
        publication='Sunday Independent'
        newspaperTitle='The Sunday Independent on zinioapps.com'
        newspaperButton={NewspaperButton}
        newspaperUrl='http://thesundayindependent.pressreader.com/'
      />
    )} />
    <Route path='/sundayindependent/news' exact render={() => (
      <TitleSectionPage {...props}
        section='sundayindependent/news'
        relatedSections={[{ id: 'sundayindependent', label: 'Sunday Independent' }, { id: 'sundayindependent/lifestyle' }, { id: 'sundayindependent/analysis' }, { id: 'sundayindependent/international' }, { id: 'sundayindependent/dispatch' }]}
        title='Sunday Independent'
        description='-'
        publication='Sunday Independent'
        newspaperTitle='The Sunday Independent on zinioapps.com'
        newspaperButton={NewspaperButton}
        newspaperUrl='http://thesundayindependent.pressreader.com/'
      />
    )} />
    <Route path='/sundayindependent/lifestyle' exact render={() => (
      <TitleSectionPage {...props}
        section='sundayindependent/lifestyle'
        relatedSections={[{ id: 'sundayindependent', label: 'Sunday Independent' }, { id: 'sundayindependent/news' }, { id: 'sundayindependent/analysis' }, { id: 'sundayindependent/international' }, { id: 'sundayindependent/dispatch' }]}
        title='Sunday Independent'
        description='-'
        publication='Sunday Independent'
        newspaperTitle='The Sunday Independent on zinioapps.com'
        newspaperButton={NewspaperButton}
        newspaperUrl='http://thesundayindependent.pressreader.com/'
      />
    )} />
    <Route path='/sundayindependent/analysis' exact render={() => (
      <TitleSectionPage {...props}
        section='sundayindependent/analysis'
        relatedSections={[{ id: 'sundayindependent', label: 'Sunday Independent' }, { id: 'sundayindependent/news' }, { id: 'sundayindependent/lifestyle' }, { id: 'sundayindependent/international' }, { id: 'sundayindependent/dispatch' }]}
        title='Sunday Independent'
        description='-'
        publication='Sunday Independent'
        newspaperTitle='The Sunday Independent on zinioapps.com'
        newspaperButton={NewspaperButton}
        newspaperUrl='http://thesundayindependent.pressreader.com/'
      />
    )} />
    <Route path='/sundayindependent/international' exact render={() => (
      <TitleSectionPage {...props}
        section='sundayindependent/international'
        relatedSections={[{ id: 'sundayindependent', label: 'Sunday Independent' }, { id: 'sundayindependent/news' }, { id: 'sundayindependent/lifestyle' }, { id: 'sundayindependent/analysis' }, { id: 'sundayindependent/dispatch' }]}
        title='Sunday Independent'
        description='-'
        publication='Sunday Independent'
        newspaperTitle='The Sunday Independent on zinioapps.com'
        newspaperButton={NewspaperButton}
        newspaperUrl='http://thesundayindependent.pressreader.com/'
      />
    )} />
    <Route path='/sundayindependent/dispatch' exact render={() => (
      <TitleSectionPage {...props}
        section='sundayindependent/dispatch'
        relatedSections={[{ id: 'sundayindependent', label: 'Sunday Independent' }, { id: 'sundayindependent/news' }, { id: 'sundayindependent/lifestyle' }, { id: 'sundayindependent/analysis' }, { id: 'sundayindependent/international' }]}
        title='Sunday Independent'
        description='-'
        publication='Sunday Independent'
        newspaperTitle='The Sunday Independent on zinioapps.com'
        newspaperButton={NewspaperButton}
        newspaperUrl='http://thesundayindependent.pressreader.com/'
      />
    )} />
    <Route path={'/sundayindependent/:section([a-zA-Z0-9-/]+)/:title*([-])*:contentKey([0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12})'} exact render={() => (
      <RenderArticle {...props} oovvuu='4c0314aa-87fa-4a57-8152-521cc269c23c' />
    )} />
    <Route path={'/sundayindependent/:title*([-])*:contentKey([0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12})'} exact render={() => (
      <RenderArticle {...props} oovvuu='4c0314aa-87fa-4a57-8152-521cc269c23c' />
    )} />
    <Route path={'/sundayindependent/:section([a-zA-Z0-9-/]+)/:title*([-])*:contentKey([0-9]{5,})'} exact render={() => (
      <RenderArticle {...props} />
    )} />
    <Route path={'/sundayindependent/:title*([-])*:contentKey([0-9]{5,})'} exact render={() => (
      <RenderArticle {...props} />
    )} />
    <Route path={'/sundayindependent/:section([a-zA-Z0-9-/]+)'} exact render={() => (
      <TagPage {...props}
        section={url2section(props.location.pathname)}
        url={props.location.pathname}
      />
    )} />
  </Switch>
)

export default SundayIndependent
